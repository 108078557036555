import { FC } from 'react'
import { useStores } from '@/utils'
import { IQuestionBase, MessageType } from '@/types'
import { QuestionTipButtonGroup } from '@/components/molecules/QuestionTipButtonGroup'
import LinkSvg from '@/public/images/common/editor-icons/link.svg'
import ReplySvg from '@/public/images/common/reply_icon.svg'
import { useRouter } from 'next/router'
import styles from './index.module.scss'

type Props = {
  question: IQuestionBase
  connected: boolean
}

export const QuestionListItemLowerPart: FC<Props> = ({ question, connected }) => {
  const { viewer, messages } = useStores()
  const router = useRouter()

  const copyLink = async () => {
    const link = `${process.env.NEXT_PUBLIC_APP_BASE_URL}/forum/${question.slug}`
    const isSuccessful = await navigator.clipboard.writeText(link).then(
      () => {
        return true
      },
      () => {
        return false
      }
    )
    const messageText = isSuccessful ? 'Link copied!' : 'Failed to copy link'

    messages.add({
      type: MessageType.Info,
      body: messageText,
      isTranslated: true,
      ttl: 5000,
    })
  }

  const reply = () => {
    router.push(`/forum/${question.slug}`)
  }

  return (
    <div className={styles.container}>
      {question?.answersCount > 0 ? (
        <div className={styles.answerGroup}>
          <span className={styles.answer}>
            {question?.answersCount}
            {question?.answersCount > 1 ? ' Answers' : ' Answer'}
          </span>
        </div>
      ) : (
        <div className={styles.ReplyBtn}>
          <button type='button' className={styles.reply} onClick={reply}>
            <ReplySvg />
          </button>
        </div>
      )}
      <div className={styles.LinkBtn}>
        <button type='button' className={styles.link} onClick={copyLink}>
          <LinkSvg />
        </button>
      </div>
      {connected &&
        question.questioner.profile.solanaAddress &&
        question.questioner.username !== viewer.viewer?.username && (
          <QuestionTipButtonGroup question={question} amountOfThanks={Number(question.amount)} hasSmallBtn />
        )}
    </div>
  )
}
